<template>
    <div class="events-list" v-if="translates && translates[langUrl]">
        <div class="events-list__title">{{ translates[langUrl].listPage_title[lang] }}</div>
        <div class="events-list__container">
            <template v-for="(item, i) in events">
                <div 
                    :key="i"
                    class="events-list__item">
                    <div class="events-list__item__text">
                        <div class="events-list__item__date">{{ dateFormatted(item.createdAt) }}</div>
                        <div class="events-list__item__name" v-html="item.subject"></div>
                        <div class="events-list__item__description" v-html="messageFormatted(item.message)"></div>
                        <div class="events-list__item__description" v-if="item.link && item.linkText"><a :href="item.link">{{ item.linkText }}</a></div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { imageSrc } from '@/helpers';
    import moment from '@/plugins/moment';


    export default {
        name: 'EventsList',
        metaInfo() {
            return {
                title: this.translates[this.langUrl] ? this.translates[this.langUrl].browserTitle[this.lang] : ''
            }
        },
        components: {},
        data: () => ({
            imageSrc,
            langUrl: 'Events'
        }),
        computed: {
            ...mapState('events', {
                events: state => state.entities
            }),
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        async mounted() {
            this.$root.$emit('preloaderShow');
            await this.getEvents();
            await store.dispatch('events/summary');
            this.$root.$emit('preloaderHide');
        },
        methods: {
            async getEvents() {
                await store.dispatch('events/fetch', {});
            },
            dateFormatted(date) {
                return moment(date).calendar()
            },
            messageFormatted(message) {
                return message.replace(/\n/g, '<br>');
            }
        }
    }

</script>

<style lang="scss">
    .events-list {
        padding: 40px 24px 0;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        @media all and (min-width: 1400px) {
            max-width: 1400px;
            padding: 70px 0 0;
        }
        @media all and (max-width: 768px) {
            padding: 30px 20px 0;
        }
        &__title{
            display: flex;
            justify-content: flex-start;
            font-weight: 600;
            font-size: 28px;
            line-height: 130%;
            letter-spacing: -0.02em;
            color: #273155;
        }
        &__container{
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 30px;
            flex-wrap: wrap;
            margin-top: 30px;
            @media all and (max-width: 768px) {
                flex-direction: column;
                font-size: 12px;
            }
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
            border-radius: 12px;
            overflow: hidden;
            width: 445px;
            // min-width: 350px;
            &:last-of-type {
                margin-right: 0;
            }
            @media all and (max-width: 768px) {
                width: 335px;
                margin-bottom: 20px;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            &__text {
                padding: 24px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                position: relative;
            }
            &__date {
                font-size: 12px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #93A3B4;
            }
            &__name {
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.02em;
                color: #273155;
                text-align: left;
                margin-top: 12px;
            }
            &__description {
                font-weight: normal;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #273155;
                text-align: left;
                margin-top: 8px;
                overflow: hidden;
            }
            &__more {
                font-weight: normal;
                font-size: 16px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #D80765;
                margin-top: 10px;
                cursor: pointer;
            }
        }
    }
</style>